import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { Container, Form } from "react-bootstrap";
import Notification from "../components/Notification";
import { useNavigate } from "react-router";
import { getLocalData, setLocalData } from "../services/auth";

const UpdateCrypto = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
    btc: "",
    eth: "",
    usdt: "",
    email: "info@orbitaltrades.com",
  });
  const [cryptos, setCryptos] = useState([]);
  const [updatingBTC, setUpdatingBTC] = useState(false);
  const [updatingETH, setUpdatingETH] = useState(false);
  const [updatingUSDT, setUpdatingUSDT] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formData.btc === "" && formData.eth === "" && formData.usdt === "")
        throw new Error("All fields cannot be empty");

      setIsLoading(true);

      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/setcrypto", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(formData),
        })
      ).json();

      setCryptos(result);
      setLocalData("cryptoData", result);

      setColor("success");
      setMessage("Crypto updated successfuly!");
      setIsLoading(false);
      setShowAlert(true);
      setFormData({
        btc: "",
        eth: "",
        usdt: "",
        email: "info@orbitaltrades.com",
      });
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const data = JSON.parse(getLocalData("userData"));
    const cryptoData = JSON.parse(getLocalData("cryptoData"));

    setCryptos(cryptoData);

    if (data.profile.role !== "super") {
      navigate("/dashboard");
    }
  }, []);

  // --------------- Handle Display Crypto ------------------
  const handleDisplayCrypto = async (e) => {
    const target = e.target.value;
    const oppositeDisplay = !cryptos[e.target.value];

    try {
      if (target === "showBtc") setUpdatingBTC(true);
      if (target === "showEth") setUpdatingETH(true);
      if (target === "showUsdt") setUpdatingUSDT(true);

      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/displaycrypto", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: "info@orbitaltrades.com",
            field: target,
            value: oppositeDisplay,
          }),
        })
      ).json();

      setCryptos(result);
      setLocalData("cryptoData", result);
      setUpdatingBTC(false);
      setUpdatingETH(false);
      setUpdatingUSDT(false);
    } catch (error) {
      console.log(error);
      setUpdatingBTC(false);
      setUpdatingETH(false);
      setUpdatingUSDT(false);
    }
  };

  return (
    <>
      <LoggedInNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <Container className="formContainer">
        <div className="formHolder">
          <h3 id="formTitle">Update Crypto Addresses</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label id="formLabel">Bitcoin (BTC)</Form.Label>
              <Form.Control
                type="text"
                name="btc"
                placeholder={cryptos?.btc}
                className="shadow-none"
                value={formData.btc}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label id="formLabel">Ethereum</Form.Label>
              <Form.Control
                type="text"
                name="eth"
                placeholder={cryptos?.eth}
                className="shadow-none"
                value={formData.eth}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label id="formLabel">USDT (TRC20)</Form.Label>
              <Form.Control
                type="text"
                name="usdt"
                placeholder={cryptos?.usdt}
                className="shadow-none"
                value={formData.usdt}
                onChange={handleChange}
              />
            </Form.Group>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Update"}
            </button>
          </Form>
          <hr />
          
          {/* ========== Display Buttons ================ */}
          <div className="butMain">
            <div className="buttWrappers">
              <div className="butclose">
                <span style={{ fontWeight: "600" }}>BTC</span>
                <button
                  className="displayBut"
                  value={"showBtc"}
                  id="btc"
                  onClick={(e) => handleDisplayCrypto(e)}
                  disabled={updatingBTC}
                >
                  {updatingBTC ? <Loader /> : cryptos["showBtc"] ? "ON" : "OFF"}
                </button>
              </div>

              <div className="butclose">
                <span style={{ fontWeight: "600" }}>ETH</span>
                <button
                  className="displayBut"
                  value={"showEth"}
                  id="eth"
                  onClick={(e) => handleDisplayCrypto(e)}
                  disabled={updatingETH}
                >
                  {updatingETH ? <Loader /> : cryptos["showEth"] ? "ON" : "OFF"}
                </button>
              </div>

              <div className="butclose">
                <span style={{ fontWeight: "600" }}>USDT</span>
                <button
                  className="displayBut"
                  value={"showUsdt"}
                  id="usdt"
                  onClick={(e) => handleDisplayCrypto(e)}
                  disabled={updatingUSDT}
                >
                  {updatingUSDT ? (
                    <Loader />
                  ) : cryptos["showUsdt"] ? (
                    "ON"
                  ) : (
                    "OFF"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default UpdateCrypto;
