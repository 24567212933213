import LoggedInNavbar from "../components/LoggedInNavbar";
import {
  Col,
  Container,
  Form,
  Modal,
  Overlay,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import dollar from "../assets/dollar.png";
import cubes from "../assets/cubes.png";
import ref from "../assets/ref.png";
import withdraw from "../assets/withdraw.png";
import wallet from "../assets/wallet.png";
import clip from "../assets/clip.png";
import { useState, useRef, useContext, useEffect } from "react";
import { dashboardContext } from "../App";
import { useNavigate } from "react-router";
import { getLocalData, setLocalData } from "../services/auth";
import Loader from "../components/Loader";
import moment from "moment/moment";
import TradePrice from "../components/TradePrice";

const Dashboard = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(dashboardContext);
  const [transactions, setTransactions] = useState(
    JSON.parse(getLocalData("transactions"))
  );
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState("");
  const [isFetching, setIsfetching] = useState(false);

  useEffect(() => {
    document.title = "Bitloomchain | Dashboard";
  }, []);

  const hideModal = () => {
    setErr("");
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  // ---------- Check if logged in, if so then set userData ------------------
  useEffect(() => {
    const userData = JSON.parse(getLocalData("userData"));
    if (!userData || userData.msg) return navigate("/");
    if (
      userData.profile.role === "admin" ||
      userData.profile.role === "super"
    ) {
      navigate("/userlist");
    } else {
      navigate("/dashboard");
    }
  }, []);

  // ------------ Function to copy referal link -------------------
  function copy() {
    navigator.clipboard.writeText(userData.profile.referralLink);
    setShow(!show);
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }

  // ------------ Fetch updated user ----------------------
  useEffect(() => {
    const localData = JSON.parse(getLocalData("userProfile"));

    setIsfetching(true);

    const handleFreshUserDetails = async () => {
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getuserdata", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: localData.email,
          }),
        })
      ).json();

      setUserData(res);
      setLocalData("userData", res);
      setLocalData("cryptoData", res?.crypto);
      setLocalData("plans", res?.plans);
      setLocalData("transactions", res?.transaction);
      setTransactions(res?.transaction);
      setIsfetching(false);
    };
    handleFreshUserDetails();
  }, []);

  // -------------------------- Withdraw logic -----------------------
  const handleWithdraw = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      if (Number(withdrawalAmount) > userData.profile.availableWithdrawal) {
        throw new Error(
          "The withdrawal amount cannot exceed the available withdrawal balance."
        );
      } else if (Number(withdrawalAmount) <= 0) {
        throw new Error("The withdrawal amount cannot be Zero or less.");
      }

      const res = await (
        await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/handleUserwithdrawalrequest",
          {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              email: userData.profile.email,
              userId: userData.profile.id,
              withdrawalAmount,
            }),
          }
        )
      ).json();

      setLocalData("transactions", res.transaction);
      setTransactions(res.transaction);
      setUserData(res);
      setIsLoading(false);
      setShowModal(false);
      setErr("");
    } catch (error) {
      console.log(error);
      setErr(error.message);
      setIsLoading(false);
    }
  };

  // ==================== Withdrawal Modal ===========================
  const withdrawModal = () => {
    return (
      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleWithdraw(e)}>
            <Form.Group className="mb-3">
              <Form.Label>
                Please go to your{" "}
                <strong style={{ color: "#625f5f" }}>Profile</strong> and update
                your receiving crypto wallet network and wallet address, if you
                haven’t done so already.
              </Form.Label>
              <Form.Label>
                {" "}
                <strong> Enter withdrawal amount</strong>
              </Form.Label>

              <Form.Control
                required
                type="number"
                placeholder="Enter The amount you wish to withdraw"
                onChange={(e) => setWithdrawalAmount(e.target.value)}
              />
              <div style={{ height: "20px" }}>
                <span style={{ color: "red", fontSize: "13px" }}>{err}</span>
              </div>
            </Form.Group>

            <button
              className={`butt`}
              style={{
                width: "162px",
                height: "44px",
                marginTop: "40px",
                marginBottom: "10px",
              }}
            >
              {isLoading ? <Loader /> : "Request Withdrawal"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const validateWithdrawal =
    userData.profile && userData.profile.availableWithdrawal > 0;

  return (
    <div>
      <TradePrice />
      <LoggedInNavbar isFetching={isFetching} />

      {withdrawModal()}

      <Container fluid className="dashboard">
        <span id="dashboardLabel">Referral Link</span>

        <div className="clipSection" ref={target} onClick={copy}>
          <input
            type="text"
            readOnly
            className="clipboardinput"
            value={userData.profile && userData.profile.referralLink}
          />
          <div className="clipIconHolder">
            <img src={clip} alt="clip" id="clipIcon" />
          </div>
        </div>

        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Link copied!
            </Tooltip>
          )}
        </Overlay>

        <Row>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Wallet Balance</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalWalletBalance.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={wallet} alt="icon" />
            </div>
          </Col>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Investments</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalInvestment.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={cubes} alt="icon" />
            </div>
          </Col>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Investments Profit</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.activeInvestment.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={dollar} alt="icon" />
            </div>
          </Col>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="case">
              <div className="dashboardCard">
                <div className="dashboardCardContent">
                  <span>Available Withdrawal</span>
                  <h3>{`$${
                    userData.profile
                      ? userData.profile.availableWithdrawal.toLocaleString()
                      : 0
                  }`}</h3>
                </div>

                <button
                  className={`butt ${validateWithdrawal ? "" : "inactive"}`}
                  disabled={validateWithdrawal ? false : true}
                  onClick={openModal}
                >
                  Withdraw
                </button>
              </div>

              <div className="cover">
                <div className="partOne">
                  <span
                    style={{
                      color: "rgb(186 105 7)",
                      fontSize: 12,
                      width: "50%",
                    }}
                  >
                    Please note that the withdraw button is only active when
                    there are withdrawable funds available.
                  </span>
                </div>
                {/* <div className="partOne"></div> */}
              </div>
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Withdrawal</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalWithdrawal.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={withdraw} alt="icon" />
            </div>
          </Col>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Referral Earning</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.referralEarning.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={ref} alt="icon" />
            </div>
          </Col>
        </Row>
        <br />

        <span id="dashboardLabel">Transactions</span>

        <div>
          <Table size="sm" responsive>
            <tr>
              <th>S/N</th>
              <th>Date</th>
              <th>Reference ID</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Profit</th>
              <th>Details</th>
              <th>Status</th>
            </tr>
            <>
              {transactions &&
                transactions.map(
                  (
                    {
                      id,
                      amount,
                      createdAt,
                      details,
                      profit,
                      referenceId,
                      status,
                      type,
                    },
                    index
                  ) => {
                    return (
                      <tr key={id}>
                        <td>{index + 1}</td>
                        <td>
                          {moment.utc(createdAt.split("T")[0]).format("ll")}
                        </td>
                        <td id="referrenceID">{referenceId}</td>
                        <td>{type}</td>

                        <td
                          style={{
                            color: `${
                              type === "Withdrawal Request" ? "red" : ""
                            }`,
                          }}
                        >
                          {` ${
                            type === "Withdrawal Request" ? "-" : ""
                          } $${amount.toLocaleString()}`}
                        </td>

                        <td id="plusAmount">
                          {profit === 0 ? "" : `+$${profit.toLocaleString()}`}
                        </td>
                        <td>{details}</td>
                        <td>
                          <span
                            style={{
                              color:
                                status === "pending"
                                  ? "rgb(120 99 9)"
                                  : status === "invalid"
                                  ? "red"
                                  : status === "active"
                                  ? "#F76300"
                                  : "",
                              fontWeight: "600",
                            }}
                          >
                            {status}
                          </span>
                        </td>
                      </tr>
                    );
                  }
                )}
            </>
          </Table>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
