import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminTransactions from "./Pages/AdminTransactions";
import ChangePassword from "./Pages/ChangePassword";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import Profile from "./Pages/Profile";
import Referral from "./Pages/Referral";
import ResetPassword from "./Pages/ResetPassword";
import SignUp from "./Pages/SignUp";
import Support from "./Pages/Support";
import Users from "./Pages/Users";
import { getLocalData, setLocalData } from "./services/auth";
import PaymentPage from "./Pages/PaymentPage";
import Manage from "./Pages/Manage";
import UpdateCrypto from "./Pages/UpdateCrypto";
import InvestmentPlans from "./components/InvestmentPlans";
import UpdatePlan from "./Pages/UpdatePlan";
import useInactivity from "./components/useInactivity";
import UserList from "./Pages/UserList";
import UserTransaction from "./Pages/UserTransaction"

export const dashboardContext = createContext(null);
export const userContext = createContext(null);

const App = () => {
  const [userData, setUserData] = useState([]);

   // Use the inactivity hook to clear local storage and redirect to login if inactive for 30 minutes
   useInactivity(1800000);

  // ------------ Fetch updated user ----------------------
  useEffect(() => {
    const localData = JSON.parse(getLocalData("userProfile"));

    if (userData) {
      try {
        const handleFreshUserDetails = async () => {
          const res = await (
            await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getuserdata", {
              method: "POST",

              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({
                email: localData.email,
              }),
            })
          ).json();

          setUserData(res);
          setLocalData("userData", res);
          setLocalData("cryptoData", res?.crypto);
          setLocalData("plans", res?.plans);
          setLocalData("transactions", res?.transaction);
        };

        handleFreshUserDetails();
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  // -------------- Check if user status is active, else log user out---------------------------
  useEffect(() => {
    if (
      (userData.profile && userData.profile.status === "blocked") ||
      userData.msg
    ) {
      localStorage.removeItem("userData");
      localStorage.removeItem("newTransaction");
      localStorage.removeItem("userProfile");
      localStorage.removeItem("recentTransaction");

      window.location.href = "/";
    }
  }, [userData]);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={(() => {
              if (window.location.pathname === "/") {
                window.location.replace("https://bitloomchain.com/");
              }
              return null;
            })()}
          />
          <Route
            path="/login"
            element={
              <dashboardContext.Provider value={{ userData, setUserData }}>
                <Login />
              </dashboardContext.Provider>
            }
          />{" "}
          <Route
            path="/register"
            element={
              <dashboardContext.Provider value={{ userData, setUserData }}>
                <SignUp />
              </dashboardContext.Provider>
            }
          />{" "}
          <Route path="/resetpassword" element={<ResetPassword />} />{" "}
          <Route
            path="/dashboard"
            element={
              <dashboardContext.Provider value={{ userData, setUserData }}>
                <Dashboard />
              </dashboardContext.Provider>
            }
          />{" "}
          <Route path="/plans" element={<InvestmentPlans />} />{" "}
          <Route path="/referrals" element={<Referral />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/users" element={<Users />} />{" "}
          <Route path="/transactions" element={<AdminTransactions />} />{" "}
          <Route path="/changepassword" element={<ChangePassword />} />{" "}
          <Route path="/support" element={<Support />} />{" "}
          <Route path="/payment" element={<PaymentPage />} />{" "}
          <Route path="/manage" element={<Manage />} />{" "}
          <Route path="/updatecrypto" element={<UpdateCrypto />} />{" "}
          <Route path="/updateplan" element={<UpdatePlan />} />{" "}
          <Route path="/userlist" element={<UserList />} />{" "}
          <Route path="/usertransaction" element={<UserTransaction />} />{" "}
        </Routes>{" "}
      </Router>
    </>
  );
};

export default App;
