import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { getLocalData, setLocalData } from "../services/auth";
import Loader from "../components/Loader";
import FetchLoader from "../components/FetchLoader";
import DynamicFooter from "../components/DynamicFooter";
import moment from "moment";

const AdminTransactions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.data || "";
  const [userRole, setUserRole] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [userData, setUserData] = useState([]);
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);

  const [transactionId, setTransactionId] = useState("");
  const [transactionEmail, setTransactionEmail] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [valueToUpdate, setValueToUpdate] = useState("");
  const [nameOfAction, setNameOfAction] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [loadAmount, setLoadAmount] = useState(null);

  // ------------ Modal state -------------------------
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActionModel, setShowActionModel] = useState(false);

  useEffect(() => {
    document.title = "Bitloomchain | Transactions";
  }, []);

  // -------------- Modal close --------------------------
  const handleDeleteClose = () => setShowDeleteModal(false);

  const handleCloseActionModel = () => {
    setShowActionModel(false);
    setErr("");
    setIsLoading(false);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  // -------------- Check Current User Role to see if eligible to view the users page ------------
  useEffect(() => {
    const currentUser = JSON.parse(getLocalData("userData"));
    if (
      currentUser.profile.role === "super" ||
      currentUser.profile.role === "admin"
    ) {
      return setUserRole(currentUser.profile.role);
    }
    navigate("/dashboard");
  }, []);

  // -------- Get user from the server ----------------
  useEffect(() => {
    setIsFetching(true);
    const getAllUsers = async () => {
      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getusertransaction", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
      ).json();

      setTransactions(result.transaction);
      setUserData({ profile: result.profile });
      setIsFetching(false);
    };

    getAllUsers();
  }, []);

  // ----- ON CHANGED ----------------
  useEffect(() => {
    const getAllUsers = async () => {
      setIsFetching(true);
      try {
        const result = await (
          await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getusertransaction", {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ email }),
          })
        ).json();

        setTransactions(result.transaction);
        setUserData({ profile: result.profile });
      } catch (error) {
        setErr("Failed to fetch transactions");
      }
      setIsFetching(false);
    };

    getAllUsers();
  }, [hasChanged]);

  // ============ Logic to grab neccessary data for manipulation of transactions =======================
  const handleDeleteData = (
    e,
    transactionEmail,
    transactionId,
    nameOfFunctionality
  ) => {
    setShowDeleteModal(true);
    setTransactionEmail(transactionEmail);
    setTransactionId(transactionId);
  };

  const handleGrabTransactionData = (
    e,
    transactionId,
    transactionEmail,
    transactionStatus,
    transactionType
  ) => {
    setShowActionModel(true);

    const nameOfAction = e.target.name;
    const value = e.target.value;

    setTransactionId(transactionId);
    setTransactionEmail(transactionEmail);
    setValueToUpdate(value);
    setNameOfAction(nameOfAction);
    setTransactionStatus(transactionStatus);
    setTransactionType(transactionType);
  };

  // ==================== Transaction Delete Logic ===========================
  const handleDeleteTransaction = async (e, transactionId, userRole) => {
    e.preventDefault();
    setIsLoading(true);

    let yam = await (
      await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/deletetransaction", {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ transactionId }),
      })
    ).json();

    console.log(yam);

    setTransactionEmail("");
    setTransactionId("");
    window.location.href = "/usertransaction";
  };

  // ==================== Transaction Update Logic ===========================
  const action = async (
    e,
    nameOfAction,
    transactionId,
    transactionEmail,
    valueToUpdate,
    transactionStatus,
    transactionType
  ) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let result;
      if (nameOfAction === "status") {
        result = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/updatetransactionstatus",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ transactionId, valueToUpdate }),
          }
        );
      } else if (nameOfAction === "withdrawable") {
        result = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/updatetransactionwithdrawable",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ transactionId, valueToUpdate }),
          }
        );
      } else if (nameOfAction === "updateProfit") {
        result = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/updatetransactionprofit",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ transactionId, valueToUpdate }),
          }
        );
      }

      const updatedTransaction = await result.json();

      // Update the transactions state locally with the updated transaction
      setShowActionModel(false);
      setHasChanged(!hasChanged); // Trigger a refetch
      setIsLoading(false);
      setTransactionId("");
      window.location.href = "/usertransaction";
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // ==================== Transaction Delete Modal ===========================
  const updateModal = (transactionEmail, transactionId, userRole) => {
    return (
      <Modal show={showDeleteModal} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                Are you sure you want to delete transaction for{" "}
                <span style={{ color: "rgb(144 80 0)" }}>
                  {transactionEmail}
                </span>{" "}
                ?
              </Form.Label>
            </Form.Group>
            <button
              style={{ backgroundColor: "red" }}
              id="actionBtn"
              type="submit"
              size="block"
              onClick={(e) =>
                handleDeleteTransaction(e, transactionId, userRole)
              }
            >
              {isLoading ? <Loader /> : "Delete"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  // ==================== Transaction Manipulation Modal ===========================
  const actionModal = (
    nameOfAction,
    transactionId,
    transactionEmail,
    valueToUpdate,
    transactionStatus,
    transactionType
  ) => {
    return (
      <Modal show={showActionModel} onHide={handleCloseActionModel}>
        <Modal.Header closeButton>
          <Modal.Title>Update Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) =>
              action(
                e,
                nameOfAction,
                transactionId,
                transactionEmail,
                valueToUpdate,
                transactionStatus,
                transactionType
              )
            }
          >
            <Form.Group className="mb-3">
              {nameOfAction === "updateProfit" ? (
                <Form.Group>
                  <Form.Label>
                    Enter new profit for{" "}
                    <span style={{ color: "#edb267" }}>{transactionEmail}</span>{" "}
                  </Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter profit"
                    onChange={(e) => setValueToUpdate(e.target.value)}
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {err}
                  </span>
                </Form.Group>
              ) : (
                <Form.Label>
                  Are you sure you want to make an update on{" "}
                  <span style={{ color: "rgb(144 80 0)" }}>
                    {transactionEmail}
                  </span>{" "}
                  ?
                  <br />
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {err}
                  </span>
                </Form.Label>
              )}
            </Form.Group>
            <button id="actionBtn" type="submit" variant="success" size="block">
              {isLoading ? <Loader /> : "Update"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const handleLoad = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/loadaccount", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: userData.profile.email,
            loadAmount,
          }),
        })
      ).json();

      console.log(res);
      setUserData(res);

      setShowModal(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  console.log(userData);

  // ==================== Load Modal ===========================
  const loadModal = () => {
    return (
      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Load Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleLoad}>
            <Form.Group className="mb-3">
              <Form.Label>
                This feature allows you to add a{" "}
                <strong style={{ color: "#625f5f" }}>
                  withdrawable amount
                </strong>{" "}
                directly to the client's account, without requiring the client
                to make an investment.
              </Form.Label>
              <Form.Label>
                {" "}
                <strong>Enter loading amount </strong>
              </Form.Label>

              <Form.Control
                required
                type="number"
                placeholder="Enter amount you wish to load CL account"
                onChange={(e) => setLoadAmount(e.target.value)}
              />
              <div style={{ height: "20px" }}>
                <span style={{ color: "red", fontSize: "13px" }}>{err}</span>
              </div>
            </Form.Group>

            <button
              className={`butt`}
              style={{
                width: "162px",
                height: "44px",
                marginTop: "40px",
                marginBottom: "10px",
              }}
            >
              {isLoading ? <Loader /> : "Load account"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <LoggedInNavbar />

      {isFetching ? <FetchLoader /> : ""}

      {loadModal()}

      {updateModal(transactionEmail, transactionId, userRole)}
      {actionModal(
        nameOfAction,
        transactionId,
        transactionEmail,
        valueToUpdate,
        transactionStatus,
        transactionType
      )}

      <Container style={{ marginTop: "20px" }}>
        <Row>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Wallet Balance</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalWalletBalance.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={wallet} alt="icon" /> */}
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Investments</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalInvestment.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={cubes} alt="icon" /> */}
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Investments Profit</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.activeInvestment.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={dollar} alt="icon" /> */}
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="case">
              <div className="dashboardCard">
                <div className="dashboardCardContent">
                  <span>Available Withdrawal</span>
                  <h3>{`$${
                    userData.profile
                      ? userData.profile.availableWithdrawal.toLocaleString()
                      : 0
                  }`}</h3>
                </div>
                <button className={`butt `} onClick={openModal}>
                  Load
                </button>
              </div>
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Withdrawal</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalWithdrawal.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={withdraw} alt="icon" /> */}
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Referral Earning</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.referralEarning.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={ref} alt="icon" /> */}
            </div>
          </Col>
        </Row>
      </Container>

      {/* ------------- Transaction Table ----------------------- */}
      <Container fluid>
        <h3 id="aboutText1">All Transactions</h3>
        <Table size="sm" responsive>
          <tr>
            <th>S/N</th>
            <th>User</th>
            <th>Reference ID</th>
            <th>Type</th>
            <th>Details</th>
            <th>Amount</th>
            <th>Profit</th>
            <th>Status</th>
            <th>Withdrawable</th>
            <th>Created At</th>
            {/* <th>Withdrawal Requested</th> */}
            <th>Update Profit</th>
            <th>Delete</th>
          </tr>

          <>
            {transactions &&
              transactions.map(
                (
                  {
                    email,
                    amount,
                    createdAt,
                    details,
                    profit,
                    referenceId,
                    requestedWithdrawal,
                    status,
                    type,
                    userId,
                    id,
                    withdrawalable,
                  },
                  index
                ) => (
                  <tr>
                    <td>{index + 1} </td>
                    <td>{email}</td>
                    <td id="referrenceID">{referenceId}</td>
                    <td>{type}</td>
                    <td>{details}</td>
                    <td id="plusAmount">{`$${amount}`}</td>
                    <td>{`$${profit}`}</td>

                    {/* ----------- Status --------------- */}
                    <td>
                      <select
                        name="status"
                        id="userRole"
                        onChange={(e) =>
                          handleGrabTransactionData(e, id, email, status, type)
                        }
                      >
                        <option value={status}>{status}</option>

                        <option
                          value={
                            status === "invalid" ||
                            status === "active" ||
                            status === "pending" ||
                            status === "Static"
                              ? "paid"
                              : "Static"
                          }
                        >
                          {status === "invalid" ||
                          status === "active" ||
                          status === "pending" ||
                          status === "Static"
                            ? "paid"
                            : "Static"}
                        </option>

                        <option
                          value={
                            status === "Static" ||
                            status === "active" ||
                            status === "pending" ||
                            status === "paid"
                              ? "invalid"
                              : "Static"
                          }
                        >
                          {status === "Static" ||
                          status === "active" ||
                          status === "pending" ||
                          status === "paid"
                            ? "invalid"
                            : "Static"}
                        </option>

                        <option
                          value={
                            status === "Static" ||
                            status === "invalid" ||
                            status === "active" ||
                            status === "paid"
                              ? "pending"
                              : "Static"
                          }
                        >
                          {status === "Static" ||
                          status === "invalid" ||
                          status === "active" ||
                          status === "paid"
                            ? "pending"
                            : "Static"}
                        </option>

                        <option
                          value={
                            status === "Static" ||
                            status === "invalid" ||
                            status === "pending" ||
                            status === "paid"
                              ? "active"
                              : "Static"
                          }
                        >
                          {status === "Static" ||
                          status === "invalid" ||
                          status === "pending" ||
                          status === "paid"
                            ? "active"
                            : "Static"}
                        </option>
                      </select>
                    </td>

                    {/* ---------- Withdrawable---------------- */}
                    <td>
                      <select
                        name="withdrawable"
                        id="userRole"
                        onChange={(e) =>
                          handleGrabTransactionData(e, id, email, status, type)
                        }
                      >
                        <option
                          value={withdrawalable === false ? `false` : "true"}
                        >
                          {withdrawalable === false ? `false` : "true"}
                        </option>
                        <option
                          value={withdrawalable === false ? `true` : "false"}
                        >
                          {withdrawalable === false ? `true` : "false"}
                        </option>
                      </select>
                    </td>

                    <td> {moment.utc(createdAt.split("T")[0]).format("ll")}</td>

                    {/* ---------- requestedWithdrawal ------------------- */}

                    {/* <td>{`${
                      requestedWithdrawal.toString().charAt(0).toUpperCase() +
                      requestedWithdrawal.toString().slice(1)
                    }`}</td> */}

                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        id="updateBtn"
                        name="updateProfit"
                        onClick={(e) =>
                          handleGrabTransactionData(e, id, email, status, type)
                        }
                      >
                        Update Profit
                      </Button>
                    </td>

                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        bg="danger"
                        id="deleteBtn"
                        onClick={(e) => handleDeleteData(e, email, id)}
                        disabled={
                          userRole && userRole === "admin" ? true : false
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                )
              )}
          </>
        </Table>
      </Container>
    </>
  );
};

export default AdminTransactions;
