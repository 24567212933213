import { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import Footer from "../components/Footer";
import FormsNavbar from "../components/FormsNavbar";

const ResetPassword = () => {

  useEffect(() => {
    document. title = 'Bitloomchain | Reset password';
    }, []);



  return (
    <>
      <FormsNavbar />
      <Container className="signupWrapper" id="restpassword">
        <div className="signUp" id="resetform">
          <h3 id="formTitle">Reset Password</h3>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label id="formLabel">Email</Form.Label>
              <Form.Control type="text" placeholder="Enter email" />
            </Form.Group>
          </Form>
          <button type="submit" id="logBtn">
            Reset Password
          </button>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ResetPassword;
