import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Dropdown,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { getLocalData } from "../services/auth";
import FetchLoader from "../components/FetchLoader";
import moment from "moment";
import DynamicFooter from "../components/DynamicFooter";

const UserList = () => {
  const navigate = useNavigate();
  const [allusers, setAllUsers] = useState([]);

  const [userRole, setUserRole] = useState([]);

  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    document.title = "Bitloomchain | Users";
  }, []);

  // -------------- Check Current User Role to see if eligible to view the users page ------------
  useEffect(() => {
    const currentUser = JSON.parse(getLocalData("userData"));
    if (
      currentUser.profile.role === "super" ||
      currentUser.profile.role === "admin"
    ) {
      return setUserRole(currentUser.profile.role);
    }
    navigate("/dashboard");
  }, []);

  // -------- Get all users from the server ----------------
  useEffect(() => {
    setIsloading(true);
    const getAllUsers = async () => {
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/allusers")
      ).json();

      setAllUsers(res);
      setIsloading(false);
    };

    getAllUsers();
  }, []);

  const handleManage = async (data) => {
    navigate("/usertransaction", { state: { data } });
  };

  return (
    <>
      <LoggedInNavbar />

      {isloading ? <FetchLoader /> : ""}

      <Container className="referralPage" fluid>
        <h3 id="aboutText1">All Users</h3>
        <Table size="sm" responsive>
          <tr>
            <th>S/N</th>
            <th>Fullname</th>
            <th>Email</th>
            <th>Joined At</th>
            <th>Expand</th>
          </tr>

          <>
            {allusers &&
              allusers.map(
                (
                  { id, firstName, secondName, email, role, createdAt },
                  index
                ) => (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>{`${firstName} ${secondName}`}</td>
                    <td>{email}</td>

                    <td>{moment.utc(createdAt.split("T")[0]).format("ll")}</td>

                    <td>
                      <Button
                        size="sm"
                        style={{ backgroundColor: "#278ca7", border: "none" }}
                        onClick={() => handleManage(email)}
                        disabled={
                          userRole && userRole === "admin" ? true : false
                        }
                      >
                        Manage
                      </Button>
                    </td>
                  </tr>
                )
              )}
          </>
        </Table>
      </Container>
    </>
  );
};

export default UserList;
