import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { getLocalData } from "../services/auth";

const ChangePassword = () => {
  const [userData, setUserData] = useState([]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    document.title = "Bitloomchain | Change password";
  }, []);

  useEffect(() => {
    const fetchedData = JSON.parse(getLocalData("userProfile"));
    setUserData(fetchedData);
  }, []);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const data = { _id: userData.id, oldPassword, newPassword };

    try {
      if (newPassword !== confirmNewPassword)
        throw "New password does not match";

      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/changepassword", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
      ).json();

      if (result.msg !== "Old password is incorrect") {
        setMessage(result);

        setColor("success");
        setIsLoading(false);
        setShowAlert(true);

        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        setMessage(result.msg);
        setColor("danger");
        setIsLoading(false);
        setShowAlert(true);

        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      }
    } catch (error) {
      setMessage(error.msg);
      console.log(error);
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}
      <LoggedInNavbar />

      <Container className="signupWrapper" id="restpassword">
        <div className="signUp">
          <h3 id="formTitle">Change Password</h3>
          <Form onSubmit={handleChangePassword}>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">Current Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Enter current password"
                onInput={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">New Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onInput={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">Confirm Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder=" Confirm new password"
                value={confirmNewPassword}
                onInput={(e) => setConfirmNewPassword(e.target.value)}
              />
            </Form.Group>
            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Change Password"}
            </button>
          </Form>
        </div>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default ChangePassword;
