import { Container, Dropdown, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getLocalData } from "../services/auth";
import TranslateBtn from "../components/TranslateBtn";
import InstaLoader from "../components/InstaLoader";
import logo from "../assets/logo.jpg";
import { useState } from "react";

const LoggedInNavbar = ({ isFetching }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(
    JSON.parse(getLocalData("userData"))
  );

  // =============== Log user out =====================
  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("newTransaction");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("recentTransaction");
    localStorage.removeItem("cryptoData");
    localStorage.removeItem("isPendingInvestment");
    localStorage.removeItem("showState");
    localStorage.removeItem("plans");

    window.location.href = "https://bitloomchain.com/";
  };

  const adminLinks = () => {
    const data = JSON.parse(getLocalData("userData"));

    if (data === null || data.err || data.profile === null || data.msg) {
      localStorage.removeItem("userData");
      localStorage.removeItem("newTransaction");
      localStorage.removeItem("userProfile");
      localStorage.removeItem("recentTransaction");
      localStorage.removeItem("isPendingInvestment");
      localStorage.removeItem("showState");
      window.location.href = "https://bitloomchain.com/";
      localStorage.removeItem("cryptoData");
    }

    if (data.profile.role === "super") {
      return (
        <>
          <Dropdown.Item>
            <Link id="loggedLink" to="/users">
              User (Admin)
            </Link>
          </Dropdown.Item>

          {/* <Dropdown.Item>
            <Link id="loggedLink" to="/userlist">
              Transactions (Admin)
            </Link>
          </Dropdown.Item> */}

          <Dropdown.Item>
            <Link id="loggedLink" to="/updatecrypto">
              Update Crypto (Admin)
            </Link>
          </Dropdown.Item>

          <Dropdown.Item>
            <Link id="loggedLink" to="/updateplan">
              Update Plan (Admin)
            </Link>
          </Dropdown.Item>
        </>
      );
    } else if (data.profile.role === "admin") {
      return (
        <>
          <Dropdown.Item>
            <Link id="loggedLink" to="/users">
              Users (Admin)
            </Link>
          </Dropdown.Item>

          {/* <Dropdown.Item>
            <Link id="loggedLink" to="/transactions">
              Transactions (Admin)
            </Link>
          </Dropdown.Item> */}

          <Dropdown.Item>
            <Link id="loggedLink" to="/updateplan">
              Update Plan (Admin)
            </Link>
          </Dropdown.Item>
        </>
      );
    }
  };

  return (
    <>
      <div className="dynamicNavbar">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="nav-grad"
          // fixed="top"
        >
          <Container fluid>
            {/* <NavLink to="" className="noDecoration"> */}
            <Navbar.Brand href="https://bitloomchain.com/" id="logo">
              <img src={logo} alt="" id="logo" />
            </Navbar.Brand>
            {/* </NavLink> */}

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="justify-content-end flex-grow-1 ">
                <div className="loggedNav">
                  <NavLink
                    id="loggedLink"
                    to={`${
                      userData?.profile?.role === "admin" ||
                      userData?.profile?.role === "super"
                        ? "/userlist"
                        : "/dashboard"
                    }`}
                  >
                    Dashboard
                  </NavLink>

                  <Dropdown>
                    <Dropdown.Toggle id="accountDropDown">
                      Account
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link id="loggedLink" to="/profile">
                          Profile
                        </Link>{" "}
                      </Dropdown.Item>

                      <Dropdown.Item>
                        <Link id="loggedLink" to="/changepassword">
                          Change Password
                        </Link>{" "}
                      </Dropdown.Item>

                      {adminLinks()}

                      <Dropdown.Item id="loggedLink" onClick={handleLogout}>
                        {" "}
                        Logout
                      </Dropdown.Item>

                      <Dropdown.Item id="loggedLink">
                        <TranslateBtn />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <NavLink id="loggedLink" to="/plans">
                    {" "}
                    Plan
                  </NavLink>

                  <NavLink to="/referrals" id="loggedLink">
                    Referrals
                  </NavLink>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {isFetching ? <InstaLoader /> : ""}
      </div>
    </>
  );
};

export default LoggedInNavbar;
