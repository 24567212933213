import { useEffect, useState } from "react";
import { Badge, Button, Container, Form, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { getLocalData } from "../services/auth";
import Loader from "../components/Loader";
import FetchLoader from "../components/FetchLoader";
import DynamicFooter from "../components/DynamicFooter";
import moment from "moment";

const AdminTransactions = () => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);

  const [transactionId, setTransactionId] = useState("");
  const [transactionEmail, setTransactionEmail] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [valueToUpdate, setValueToUpdate] = useState("");
  const [nameOfAction, setNameOfAction] = useState("");

  // ------------ Modal state -------------------------
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActionModel, setShowActionModel] = useState(false);

  useEffect(() => {
    document.title = "Bitloomchain | Transactions";
  }, []);

  // -------------- Modal close --------------------------
  const handleDeleteClose = () => setShowDeleteModal(false);

  const handleCloseActionModel = () => {
    setShowActionModel(false);
    setErr("");
    setIsLoading(false);
  };

  // -------------- Check Current User Role to see if eligible to view the users page ------------
  useEffect(() => {
    const currentUser = JSON.parse(getLocalData("userData"));
    if (
      currentUser.profile.role === "super" ||
      currentUser.profile.role === "admin"
    ) {
      return setUserRole(currentUser.profile.role);
    }
    navigate("/dashboard");
  }, []);

  // -------- Get all users from the server ----------------
  useState(() => {
    setIsFetching(true);
    const getAllUsers = async () => {
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getalltransactions")
      ).json();

      setTransactions(res);
      setIsFetching(false);

    };

    getAllUsers();
  }, []);

  // ----- ON CHANGED ----------------
  useEffect(() => {
    const getAllUsers = async () => {
      setIsFetching(true);
      try {
        const res = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/getalltransactions"
        );
        const data = await res.json();
        setTransactions(data);

      } catch (error) {
        setErr("Failed to fetch transactions");
      }
      setIsFetching(false);
    };

    getAllUsers();
  }, [hasChanged]);

  // ============ Logic to grab neccessary data for manipulation of transactions =======================
  const handleDeleteData = (
    e,
    transactionEmail,
    transactionId,
    nameOfFunctionality
  ) => {
    setShowDeleteModal(true);
    setTransactionEmail(transactionEmail);
    setTransactionId(transactionId);
  };

  const handleGrabTransactionData = (
    e,
    transactionId,
    transactionEmail,
    transactionStatus,
    transactionType
  ) => {
    setShowActionModel(true);

    const nameOfAction = e.target.name;
    const value = e.target.value;

    setTransactionId(transactionId);
    setTransactionEmail(transactionEmail);
    setValueToUpdate(value);
    setNameOfAction(nameOfAction);
    setTransactionStatus(transactionStatus);
    setTransactionType(transactionType);
  };

  // ==================== Transaction Delete Logic ===========================
  const handleDeleteTransaction = async (e, transactionId, userRole) => {
    e.preventDefault();
    setIsLoading(true);

    let yam = await (
      await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/deletetransaction", {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ transactionId }),
      })
    ).json();

    console.log(yam);

    setTransactionEmail("");
    setTransactionId("");
    window.location.href = "/transactions";
  };

  // ==================== Transaction Update Logic ===========================
  const action = async (
    e,
    nameOfAction,
    transactionId,
    transactionEmail,
    valueToUpdate,
    transactionStatus,
    transactionType
  ) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let result;
      if (nameOfAction === "status") {
        result = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/updatetransactionstatus",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ transactionId, valueToUpdate }),
          }
        );
      } else if (nameOfAction === "withdrawable") {
        result = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/updatetransactionwithdrawable",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ transactionId, valueToUpdate }),
          }
        );
      } else if (nameOfAction === "updateProfit") {
        result = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/updatetransactionprofit",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ transactionId, valueToUpdate }),
          }
        );
      }

      const updatedTransaction = await result.json();

      // Update the transactions state locally with the updated transaction
      setShowActionModel(false);
      setHasChanged(!hasChanged); // Trigger a refetch
      setIsLoading(false);
      setTransactionId("");
      window.location.href = "/transactions";
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  // ==================== Transaction Delete Modal ===========================
  const updateModal = (transactionEmail, transactionId, userRole) => {
    return (
      <Modal show={showDeleteModal} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                Are you sure you want to delete transaction for{" "}
                <span style={{ color: "rgb(144 80 0)" }}>
                  {transactionEmail}
                </span>{" "}
                ?
              </Form.Label>
            </Form.Group>
            <button
              style={{ backgroundColor: "red" }}
              id="actionBtn"
              type="submit"
              size="block"
              onClick={(e) =>
                handleDeleteTransaction(e, transactionId, userRole)
              }
            >
              {isLoading ? <Loader /> : "Delete"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  // ==================== Transaction Manipulation Modal ===========================
  const actionModal = (
    nameOfAction,
    transactionId,
    transactionEmail,
    valueToUpdate,
    transactionStatus,
    transactionType
  ) => {
    return (
      <Modal show={showActionModel} onHide={handleCloseActionModel}>
        <Modal.Header closeButton>
          <Modal.Title>Update Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) =>
              action(
                e,
                nameOfAction,
                transactionId,
                transactionEmail,
                valueToUpdate,
                transactionStatus,
                transactionType
              )
            }
          >
            <Form.Group className="mb-3">
              {nameOfAction === "updateProfit" ? (
                <Form.Group>
                  <Form.Label>
                    Enter new profit for{" "}
                    <span style={{ color: "#edb267" }}>{transactionEmail}</span>{" "}
                  </Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter profit"
                    onChange={(e) => setValueToUpdate(e.target.value)}
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {err}
                  </span>
                </Form.Group>
              ) : (
                <Form.Label>
                  Are you sure you want to make an update on{" "}
                  <span style={{ color: "rgb(144 80 0)" }}>
                    {transactionEmail}
                  </span>{" "}
                  ?
                  <br />
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {err}
                  </span>
                </Form.Label>
              )}
            </Form.Group>
            <button id="actionBtn" type="submit" variant="success" size="block">
              {isLoading ? <Loader /> : "Update"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <LoggedInNavbar />

      {isFetching ? <FetchLoader /> : ""}

      {updateModal(transactionEmail, transactionId, userRole)}
      {actionModal(
        nameOfAction,
        transactionId,
        transactionEmail,
        valueToUpdate,
        transactionStatus,
        transactionType
      )}

      <Container fluid>
        <h3 id="aboutText1">All Transactions</h3>
        <Table size="sm" responsive>
          <tr>
            <th>S/N</th>
            <th>User</th>
            <th>Reference ID</th>
            <th>Type</th>
            <th>Details</th>
            <th>Amount</th>
            <th>Profit</th>
            <th>Status</th>
            <th>Withdrawable</th>
            <th>Created At</th>
            <th>Withdrawal Requested</th>
            <th>Update Profit</th>
            <th>Delete</th>
          </tr>

          <>
            {transactions &&
              transactions.map(
                (
                  {
                    email,
                    amount,
                    createdAt,
                    details,
                    profit,
                    referenceId,
                    requestedWithdrawal,
                    status,
                    type,
                    userId,
                    id,
                    withdrawalable,
                  },
                  index
                ) => (
                  <tr>
                    <td>{index + 1} </td>
                    <td>{email}</td>
                    <td id="referrenceID">{referenceId}</td>
                    <td>{type}</td>
                    <td>{details}</td>
                    <td id="plusAmount">{`$${amount}`}</td>
                    <td>{`$${profit}`}</td>

                    {/* ----------- Status --------------- */}
                    <td>
                      <select
                        name="status"
                        id="userRole"
                        onChange={(e) =>
                          handleGrabTransactionData(e, id, email, status, type)
                        }
                      >
                        <option value={status}>{status}</option>

                        <option
                          value={
                            status === "invalid" ||
                            status === "active" ||
                            status === "pending" ||
                            status === "Static"
                              ? "paid"
                              : "Static"
                          }
                        >
                          {status === "invalid" ||
                          status === "active" ||
                          status === "pending" ||
                          status === "Static"
                            ? "paid"
                            : "Static"}
                        </option>

                        <option
                          value={
                            status === "Static" ||
                            status === "active" ||
                            status === "pending" ||
                            status === "paid"
                              ? "invalid"
                              : "Static"
                          }
                        >
                          {status === "Static" ||
                          status === "active" ||
                          status === "pending" ||
                          status === "paid"
                            ? "invalid"
                            : "Static"}
                        </option>

                        <option
                          value={
                            status === "Static" ||
                            status === "invalid" ||
                            status === "active" ||
                            status === "paid"
                              ? "pending"
                              : "Static"
                          }
                        >
                          {status === "Static" ||
                          status === "invalid" ||
                          status === "active" ||
                          status === "paid"
                            ? "pending"
                            : "Static"}
                        </option>

                        <option
                          value={
                            status === "Static" ||
                            status === "invalid" ||
                            status === "pending" ||
                            status === "paid"
                              ? "active"
                              : "Static"
                          }
                        >
                          {status === "Static" ||
                          status === "invalid" ||
                          status === "pending" ||
                          status === "paid"
                            ? "active"
                            : "Static"}
                        </option>
                      </select>
                    </td>

                    {/* ---------- Withdrawable---------------- */}
                    <td>
                      <select
                        name="withdrawable"
                        id="userRole"
                        onChange={(e) =>
                          handleGrabTransactionData(e, id, email, status, type)
                        }
                      >
                        <option
                          value={withdrawalable === false ? `false` : "true"}
                        >
                          {withdrawalable === false ? `false` : "true"}
                        </option>
                        <option
                          value={withdrawalable === false ? `true` : "false"}
                        >
                          {withdrawalable === false ? `true` : "false"}
                        </option>
                      </select>
                    </td>

                    <td> {moment.utc(createdAt.split("T")[0]).format("ll")}</td>

                    {/* ---------- requestedWithdrawal ------------------- */}

                    <td>{`${
                      requestedWithdrawal.toString().charAt(0).toUpperCase() +
                      requestedWithdrawal.toString().slice(1)
                    }`}</td>

                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        id="updateBtn"
                        name="updateProfit"
                        onClick={(e) =>
                          handleGrabTransactionData(e, id, email, status, type)
                        }
                      >
                        Update Profit
                      </Button>
                    </td>

                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        bg="danger"
                        id="deleteBtn"
                        onClick={(e) => handleDeleteData(e, email, id)}
                        disabled={
                          userRole && userRole === "admin" ? true : false
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                )
              )}
          </>
        </Table>
      </Container>
      {/* {isFetching ? "" : <Footer />} */}
      {/* <DynamicFooter /> */}
    </>
  );
};

export default AdminTransactions;
