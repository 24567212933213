import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import back from "../assets/back.png";
import logo from "../assets/logo.jpg"

const FormsNavbar = () => {
  return (
    <Navbar className="nav-grad" fixed="top">
      <Container fluid>
        <Navbar.Brand href="https://bitloomchain.com/" id="logo">
          <img src={logo} alt="" id="logo" />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default FormsNavbar;
