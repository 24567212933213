import { Alert } from "react-bootstrap";

const Notification = ({ message, color, setShowAlert }) => {
  return (
    <div className="notification">
      <Alert
        variant={color}
        onDurationChange={setTimeout(() => {
          setShowAlert(false);
        }, 3000)}
        id="alert"
      >
        <Alert.Heading></Alert.Heading>
        <p>{message}</p>
      </Alert>
    </div>
  );
};

export default Notification;
