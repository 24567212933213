import { useEffect, useState } from "react";
import { Col, Row, Container, Form } from "react-bootstrap";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { getLocalData, setLocalData } from "../services/auth";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import edit from "../assets/edit.png"

const Profile = () => {
  const [profileImg, setProfileImg] = useState("");
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [cryptoWalletAddress, setCryptoWalletAddress] = useState("");
  const [cryptoWalletNetwork, setCryptoWalletNetwork] = useState("");
  const [imgUpdated, setImgUpdated] = useState(false);
  const [imgSize, setImgSize] = useState(0);

  const [refresh, setRefresh] = useState(true);
  const year = new Date().getFullYear();

  useEffect(() => {
    document.title = "Bitloomchain | Profile";
  }, []);

  // -------------- Handle image upload ----------------------
  const handleAddProfile = (e) => {
    const file = e.target.files[0];

    if (file.size > 2 * 1024 * 1024) {
      setMessage("File size should not exceed 2MB");
      setColor("declineAlert");
      setShowAlert(true);
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the desired dimensions (resize/compress)
        const maxWidth = 300;
        const maxHeight = 300;
        let width = image.width;
        let height = image.height;

        // Maintain aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);
        setProfileImg(compressedBase64);

        setImgUpdated(true); // Set to true after the image is fully processed
      };
    };
  };

  // =======================================

  useEffect(() => {
    const fetchedData = JSON.parse(getLocalData("userProfile"));
    setUserData(fetchedData);
    setProfileImg(fetchedData.profile);
  }, []);

  // ------------------ Handle crypto network selection --------------------------------
  const handleCryptoSelection = (e) => {
    setCryptoWalletNetwork(e.target.value);
  };

  // --------------- Handle updating of profile ---------------------------------------
  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const data = {
      _id: userData.id,
      firstName,
      secondName,
      phone,
      country,
      cryptoWalletAddress,
      cryptoWalletNetwork,
      profileImg,
    };

    try {
      if (
        !firstName &&
        !secondName &&
        !phone &&
        !country &&
        !cryptoWalletAddress &&
        !cryptoWalletNetwork &&
        !imgUpdated
      ) {
        throw "Cannot update empty fields";
      } else if (profileImg && imgSize <= 1024) {
        const result = await (
          await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/updateprofile", {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(data),
          })
        ).json();

        setLocalData("userProfile", result);
        setRefresh(!refresh);

        setMessage("Profile updated successfully");
        setColor("success");
        setIsLoading(false);
        setShowAlert(true);

        setFirstName("");
        setSecondName("");
        setCountry("");
        setCryptoWalletAddress("");
        setCryptoWalletNetwork("");
        setPhone("");
        setImgUpdated(false);
      } else {
        const result = await (
          await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/updateprofile", {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(data),
          })
        ).json();

        setLocalData("userProfile", result);
        setRefresh(!refresh);

        setMessage("Profile updated successfully");
        setColor("success");
        setIsLoading(false);
        setShowAlert(true);

        setFirstName("");
        setSecondName("");
        setCountry("");
        setCryptoWalletAddress("");
        setCryptoWalletNetwork("");
        setPhone("");
        setImgUpdated(false);
      }
    } catch (error) {
      setMessage(error);
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchedData = JSON.parse(getLocalData("userProfile"));
    setUserData(fetchedData);
    setProfileImg(fetchedData.profile);
  }, [refresh]);

  return (
    <>
      <LoggedInNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <Container className="signupWrapper">
        <div className="profile">
          <Form onSubmit={handleUpdateProfile}>
            {/* --------------Avatar Section ------------ */}
            <Row>
              <Col>
                <div className="avatarWrapper">
                  <div
                    className="avatar"
                    style={{ backgroundImage: `url('${profileImg}')` }}
                  ></div>
                  <div className="addProfile">
                    <input
                      type="file"
                      id="profileImage"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handleAddProfile(e)}
                    />
                    <label htmlFor="profileImage" className="profileIcon">
                      <img
                        src={edit}
                        alt=""
                        className="editIcon"
                      />
                    </label>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">First Name</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    placeholder={userData.firstName}
                    onInput={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Surname</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    placeholder={userData.secondName}
                    onInput={(e) => setSecondName(e.target.value)}
                    value={secondName}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Username</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={userData.userName}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={userData.email}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Country</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    placeholder={userData.country}
                    onInput={(e) => setCountry(e.target.value)}
                    value={country}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={userData.phone}
                    onInput={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row id="cryptoDetails">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">
                    Crypto Wallet Address Network
                  </Form.Label>
                  <Form.Select required onChange={handleCryptoSelection}>
                    {/* {userData.cryptoWalletNetwork === undefined ? (
                      <option id="cantChange">
                        {userData.cryptoWalletNetwork
                          ? userData.cryptoWalletNetwork
                          : "--select crypto network--"}
                      </option>
                    ) : (
                      ""
                    )} */}

                    <option>--select crypto network--</option>
                    <option>Bitcoin (BTC)</option>
                    <option>Ethereum (ETH)</option>
                    <option>Tether (USDT)</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Crypto Wallet Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      userData.cryptoWalletAddress
                        ? userData.cryptoWalletAddress
                        : "Enter wallet address"
                    }
                    onInput={(e) => setCryptoWalletAddress(e.target.value)}
                    value={cryptoWalletAddress}
                  />
                </Form.Group>
              </Col>
            </Row>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Update"}
            </button>
          </Form>
        </div>
      </Container>
      {/* <Footer /> */}

      {/* <div className="homeFooter">
        <span>
          © 2017-{year} <span id="footerLogo">Bitloomchain</span>. All rights
          reserved
        </span>
      </div> */}
    </>
  );
};

export default Profile;
