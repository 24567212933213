import { Col, Container, Form, Row } from "react-bootstrap";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { getLocalData, setLocalData } from "../services/auth";
import Notification from "../components/Notification";
const defaultState = [
  { value: "default", name: "---Select---" },
  { value: "lowPlan", name: "Lowest Plan" },
  { value: "midPlan", name: "Middle Plan" },
  { value: "highPlan", name: "Highest Plan" },
];

const UpdatePlan = () => {
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
    email: "info@Bitloomchains.com",
    minimum: "",
    maximum: "",
    name: "",
    percent: "",
    type: "",
  });

  const [planLevel, setPlanLevel] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState(JSON.parse(getLocalData("plans")));
  const [selectedPlan, setSelectedPlan] = useState(defaultState);

  useEffect(() => {
    const storedPlans = JSON.parse(getLocalData("plans"));
    setPlans(storedPlans);

    console.log(storedPlans);
    
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePlanLevelSelection = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      ["type"]: value,
    });

    if (value === "lowPlan") setSelectedPlan(plans[0]);
    if (value === "midPlan") setSelectedPlan(plans[1]);
    if (value === "highPlan") setSelectedPlan(plans[2]);
    if (value === "default") setSelectedPlan(defaultState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      if (selectedPlan[0]?.value === "default") {
        throw new Error("Please Select Plan Level");
      } else {
        if (Number(formData.minimum) > Number(formData.maximum))
          throw new Error(
            "Minimum amount cannot be greater than Maximum account"
          );

        const result = await (
          await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/setplan", {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(formData),
          })
        ).json();

        setLocalData("plans", result);
        setPlanLevel(defaultState);
        setSelectedPlan(defaultState);
        setPlans(result);

        setFormData({
          email: "info@Bitloomchains.com",
          minimum: "",
          maximum: "",
          name: "",
          percent: "",
          type: "",
        });
        setMessage("Plan Update successful");
        setShowAlert(true);
        setColor("success");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setMessage(error.message);
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <LoggedInNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <Container className="formContainer">
        <div className="formHolder">
          <h3 id="formTitle">Update Investment Plan</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel"> Plan Level </Form.Label>
              <Form.Select
                required
                value={selectedPlan ? selectedPlan[0] : ""}
                onChange={handlePlanLevelSelection}
              >
                {planLevel.map(({ name, value }) => (
                  <option value={value}>{name}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label id="formLabel">Plan Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder={selectedPlan?.name}
                className="shadow-none"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label id="formLabel">Minimum</Form.Label>
                  <Form.Control
                    type="number"
                    name="minimum"
                    placeholder={selectedPlan?.minimum}
                    className="shadow-none"
                    value={formData.minimum}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label id="formLabel">Maximum</Form.Label>
                  <Form.Control
                    type="number"
                    name="maximum"
                    placeholder={selectedPlan?.maximum}
                    className="shadow-none"
                    value={formData.maximum}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label id="formLabel">Percentage</Form.Label>
              <Form.Control
                type="text"
                name="percent"
                placeholder={selectedPlan?.percent}
                className="shadow-none"
                value={formData.percent}
                onChange={handleChange}
              />
            </Form.Group>

            <button type="submit" id="logBtn">
              {isLoading ? <Loader /> : "Update"}
            </button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default UpdatePlan;
